import React from "react"
import { Link } from "gatsby"
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles';
import {Button, Grid, Typography} from '@material-ui/core';
import { Label } from "@material-ui/icons";
import { DarkToggle } from "../utils/DarkToggle";

const useStyles = makeStyles((theme) => ({
  redlabsBG: {
    color: '#FFF',
    backgroundColor: '#c11f1f', //#8a1818,
    '& a': {
      color: 'white',
    },
  },
  spaceUpDown: {

  },
  bgWhite: {
    backgroundColor: "white"
  },
  webContent: {
    maxWidth: '1170px',
    margin: 'auto',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  webContentShadow: {
    backgroundColor: theme.backgroundColor,
    boxShadow: '0px 0 24px -7px rgba(0,0,0,.65)',
  },
  fullWidth: {
    width: '100%',
  },
  padding: {
    padding: '10px 40px',
    [theme.breakpoints.down("xs")]:
    {
      padding: '10px 10px',
    }
  },
  navMenuTop: {
    display: 'flex', 
    alignItems: 'center',
    '& a': {
      textDecoration: 'none',
    },
    '& button': {
      paddingRight: '15px',
      padding: '6px 12px',
      textTransform: 'uppercase',
      color: 'white',
      fontWeight: '400',
    }
  }
}));

const Layout = ({ location, title, children }) => {
  const classes = useStyles();
  //const rootPath = `${__PATH_PREFIX__}/`
  let header

  header = (
    <div style={{display: 'grid', justifyContent: 'space-between', gridAutoFlow: 'column'}}>
      <Link to={`/`} style={{display:'flex', flexDirection:'row', alignItems: 'center', textDecoration: 'none'}}>
        <img src={`/icons/icon-72x72.png`} alt="Redlabs Logo" style={{margin: 0, }} />
        <Typography style={{letterSacing: '3px', textTransform: 'uppercase', fontSize: '24px', paddingLeft: '20px'}}>Redlabs</Typography>
      </Link>
    </div>
  )

  return (
    <div>
      <header>
        <nav className={clsx(classes.padding, classes.webContent, classes.webContentShadow, classes.bgWhite)}>
          {header}
        </nav>
      </header>
      <div className={clsx(classes.redlabsBG)}>
        <nav className={clsx(classes.webContent, classes.navMenuTop, classes.padding)}>
          <Link to={`/`}><Button>Artikel</Button></Link>
          <Link to={`/toolbox/widerstand-e-reihe-bestimmen`}><Button>Widerstandsreihen</Button></Link>
        </nav>
      </div>
      <main className={clsx(classes.padding, classes.webContent, classes.webContentShadow, classes.bgWhite)} style={{paddingTop: "40px"}}>
        {children}
      </main>
      <footer className={clsx(classes.padding, classes.redlabsBG)}>
        <Grid container className={clsx(classes.webContent)} spacing={2} style={{justifyContent: 'space-between'}}>
          <Grid item>
          <Link to={`/`}>REDLABS {new Date().getFullYear()}</Link>
          </Grid>
          <Grid item>
            <a href={`mailto:team@redlabs.de`}>Kontakt</a>
          </Grid>
          <Grid item>
            <Link to={`/impress`}>Impressum</Link>
          </Grid>
        </Grid>
      </footer>
    </div>
  )
}

export default Layout
